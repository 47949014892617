////////////////////////////////////////////////////////////////////////////////
//

body {
  font-weight: 400;
  // background-color: #d5e3bc;
  // background-color: #d1e4dd;
  // background-color: #d1e0d7;
}

////////////////////////////////////////////////////////////////////////////////

// .cta_whatsapp_call_button {
//   $whatsapp-color: #01e675;
//   $transition-duration: 0.2;
//   //
//   position: fixed;
//   z-index: $z-index-cs-high;
//   right: $spacer * 3;
//   bottom: $spacer * 8;
//   padding: $spacer;
//   @extend .shadow;
//   background-color: $whatsapp-color; //#128c7e;
//   color: white;
//   @extend .rounded-circle;

//   .inner {
//     display: flex;
//     align-items: center;
//   }

//   &[data-orientation="portrait"] {
//     right: $spacer;
//     bottom: $spacer;
//   }

//   &:before,
//   &:after {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;

//     border-radius: 50%;

//     pointer-events: none;
//     transition: all 0.5s ease;
//     transform-origin: center;

//     background: rgba($whatsapp-color, 0.25);
//     transition: transform ease-out $transition-duration;
//   }
//   &:after {
//     background: rgba($whatsapp-color, 0.1);
//   }

//   &:hover {
//     &:before {
//       transform: scale(1.5);
//     }
//     &:after {
//       transition-duration: $transition-duration * 3;
//       transform: scale(4);
//     }
//   }
// }

////////////////////////////////////////////////////////////////////////////////
//

.container {
  // max-width: 1420px !important;
  max-width: 1080px !important;
}

.row-wrapper {
  @extend .overflow-hidden;
}

.hide-image {
  // display: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

////////////////////////////////////////////////////////////////////////////////
// root styles

// .display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
//   font-family: 'Spartan', sans-serif;
//   font-weight: 700;
// }

////////////////////////////////////////////////////////////////////////////////
//

.svg-wrapper {
  svg {
    width: 100%;
    height: auto;
  }
}

// Selects all elements with a title attribute containing the word
// [class~="bootstrap-"] {
[class^="bootstrap-"] {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
}

main.main {
  > section {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
  }
}

// /////////////////////////////////////////////////////////////////////////////

.page-header {
}
.page-footer {
  margin-top: $spacer * 4;
  padding-top: $spacer * 4;
  margin-bottom: $spacer * 3;

  text-align: center;

  .logo-rsu {
    margin-bottom: $spacer * 2;
    svg {
      max-width: 70px;
    }
  }
  .logo-in {
    margin-bottom: $spacer/2;
    svg {
      max-width: 120px;
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////

.header-hero {
  padding: $spacer * 3;
  // background-image: url();
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #002e09;

  @include media-breakpoint-down(md) {
    padding: $spacer;
    .wrapper {
      .image-hero {
        margin-bottom: $spacer !important;
        .content {
          padding-top: $spacer; //3vw; //$spacer * 3;
          padding-left: $spacer;
          padding-right: $spacer;
        }
      }
      > .content {
        padding: $spacer;
      }
    }
  }
  &[data-device="mobile"] {
    padding: $spacer;
    .wrapper {
      .image-hero {
        margin-bottom: $spacer;
        .content {
          padding-top: $spacer; //3vw; //$spacer * 3;
          padding-left: $spacer;
          padding-right: $spacer;
        }
      }
      > .content {
        padding: $spacer;
      }
    }
  }

  .wrapper {
    position: relative;
    max-width: 960px; //1024px;
    margin: 0 auto;

    .image-hero {
      position: relative;
      // content: "";
      // position: absolute;
      // top: 0;
      // left: 50%;
      // margin-left: -50%;
      width: 100%;
      margin: 0 auto;
      margin-bottom: $spacer * 3;

      @extend .rounded;
      overflow: hidden;

      img {
        width: 100% !important;
        pointer-events: none;
      }

      .content {
        position: absolute;
        top: 0;
        right: 0;
        width: 44%; //38.6%; //61.4%;
        height: 100%;

        @extend .d-flex;
        @extend .justify-content-center;

        padding-top: 3vw; //$spacer * 3;
        padding-left: $spacer;
        padding-right: $spacer;

        .logo {
          // position: absolute;
          // top: 10%;
          // left: 60%;
          svg {
            width: 100%;
            max-width: 250px;
          }
        }
      }
    }

    > .content {
      position: relative;
      @extend .d-flex;
      @extend .justify-content-end;

      padding: $spacer * 2;
      color: white;
      text-align: center;
    }
  } // wrapper
} // .header-hero

// /////////////////////////////////////////////////////////////////////////////////

.section-block {
  margin-top: $spacer * 4;
  margin-bottom: $spacer * 5;
}
