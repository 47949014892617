/*!
* defaults
*/

// body {
//   font-weight: 400;
// }

//? *****************************
//? Display headings

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  // font-family: "Fuzzy Bubbles", cursive;
  line-height: 1.2;
}

//? *****************************
//? headers

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  // margin-top: $spacer;
  margin-bottom: $spacer;

  > small {
    // margin-top: $spacer/4;
    font-size: 85%;
    // font-weight: 100;
    // font-weight: $font-weight-lighter;
    color: $gray-600;
    // line-height: normal;
  }

  &.header-light {
    small {
      color: lighten($body-color, 80%);
    }
  }

  &.underline {
    padding-bottom: $headings-margin-bottom;
    border-bottom: 1px solid $body-color;
  }
}

h1,
.h1 {
  @include media-breakpoint-down(md) {
    font-size: $h2-font-size;
  }
}
h2,
.h2 {
  @include media-breakpoint-down(md) {
    font-size: $h3-font-size;
  }
}
// h1,
// .h1,
// h2,
// .h2 {
//   // margin-bottom: $spacer;
//   // font-weight: 100;
// }
h3,
.h3 {
  // margin-bottom: $spacer;
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size;
  }
}
h4,
.h4 {
  // margin-bottom: $spacer;
  @include media-breakpoint-down(md) {
    font-size: $h5-font-size;
  }
}

// h5, h6 ,
// .h5, .h6 {
//   margin-bottom: $spacer/2;
// }

////////////////////////////////////////////////////////////////////////////////

a {
  text-decoration: none;

  &:hover {
    // color: $cli-link-hover;
    text-decoration: underline;
  }
  //   // &:visited {
  //   //   color: $cli-link-hover;
  //   // }
}

small {
  line-height: 1.2;
  display: inline-block;
}

strong,
b {
  font-weight: bold;
}

// i, em {
//   font-style: oblique;
//   font-style: italic;
// }

// p {
//   @include media-breakpoint-down(md) {
//     line-height: normal;
//   }
// }

////////////////////////////////////////////////////////////////////////////////
// list

// ul:not(.nav),
// ol {
//   padding-left: $spacer * 2;
//   // padding-right: $spacer;
// }

ul,
ol {
  // position: relative;
  // left: $spacer;
  // padding-right: $spacer;
  // list-style-position: outside;
  // list-style-type: square;

  margin-block-start: 0; //1em;
  margin-block-end: 0; //1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0; //40px;
}

ol.japan-style {
  list-style-type: hiragana;
}

// dl {
//   dt {
//
//   }
// }

ul.custom-list {
  $list-offset: $spacer * 1.5;

  // margin-bottom: $spacer;
  position: relative;
  // padding-left: $list-offset;

  // margin-block-start: 0;
  // margin-block-end: 0;
  // margin-inline-start: 0;
  // margin-inline-end: 0;
  // padding-inline-start: 0;
  // padding-inline-end: 0;

  margin-bottom: $spacer;
  padding: 0;

  list-style: none;

  li {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: $spacer/2;
    padding-right: 0;
    padding-left: $list-offset;

    &:before {
      font-family: sans-serif;
      content: "";
      position: absolute;
      top: $font-size-base/2;
      right: auto;
      left: 0;
      width: 12px;
      height: 4px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA0Ij48cGF0aCBkPSJNMTAsMEgyQTIsMiwwLDAsMCwyLDRoOGEyLDIsMCwwLDAsMC00WiIgZmlsbD0iI2Y2NTA1OCIvPjwvc3ZnPg==");
      background-size: contain;
      background-repeat: no-repeat;
      // color: $brand-red;
      font-size: 8px;
    }
  }

  &.list-right {
    // direction: rtl;
    text-align: right;

    li {
      padding-right: $list-offset;
      padding-left: 0;
      &:before {
        font-family: sans-serif;
        content: "\25C0";
        position: absolute;
        right: 0;
        left: auto;
      }
    }
  }

  &.lead {
    li {
      &:before {
        top: $lead-font-size/1.6;
      }
    }
  }
} // custom-list

////////////////////////////////////////////////////////////////////////////////
// blockquote

// blockquote {
//   $border-color: lighten($body-color,94%);
//   $border-style: solid;
//   position: relative;
//   margin: $spacer*2 0;
//   padding: $spacer/3 0;
//   padding-left: $spacer*3;
//   // border-left: 10px $border-style $border-color;
//   font-size: 130%;
//   // color: lighten($body-color,40%);
//
//   p:last-child {
//     margin-bottom:0;
//   }
//
//   i, em {
//     font-weight: lighter;
//   }
//   b, strong {
//     font-weight: bolder;
//   }
//
//   &:before {
//     content: "\201D"; // &rdquo;
//     position: absolute;
//     top: $spacer/5;
//     left: $spacer/4;
//     font-family: Georgia, serif;
//     color: lighten($body-color,70%);
//     font-size: 400%;
//     line-height: 1;
//   }
//   // &:after {
//   //   content: "";
//   //   position: absolute;
//   //   top: 40px;
//   //   left: $spacer/2;
//   //   height: 60%;
//   //   border-left: 10px $border-style $border-color;
//   // }
// }

////////////////////////////////////////////////////////////////////////////////

figure {
  margin: 0;
  figcaption {
    padding-top: $spacer/3;
    //font-style: italic;
    //font-style: oblique;
    font-size: small;
  }
}

picture {
  display: block;
}

////////////////////////////////////////////////////////////////////////////////
// last-child

table,
blockquote,
ul,
ol,
dl,
li,
dt,
dd,
p {
  &:last-child {
    margin-bottom: 0;
  }
}

////////////////////////////////////////////////////////////////////////////////

hr {
  // margin-top: $spacer*2;
  // margin-bottom: $spacer*2;

  &.sm {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  &.lg {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
  }

  &.transparent {
    background-color: transparent;
  }
}

////////////////////////////////////////////////////////////////////////////////

.svg {
  max-width: 100%;
  > svg {
    max-width: 100%;
    height: auto;
  }
}

////////////////////////////////////////////////////////////////////////////////
